<template>
  <div>
    <web-header active-url="/books" borderBottom></web-header>
    <div class="page-article-list">
      <div class="f-wrap" v-loading="loading">
        <div class="page-header">
          <div class="page-header-en">PUBLICATIONS</div>
          <span class="page-header-cn">出版物</span>
        </div>
        <template v-if="tabList && tabList.length > 1">
          <div class="page-header-tab">
            <div v-for="(item, index) in tabList" :class="{ 'page-header-tab-item':true, 'active': item.id == tabId}" @click="changeTab(item)" :key="index">
              <span class="page-header-tab-item-text">{{ item.class_name }}</span>
            </div>
          </div>
          <div class="page-header-desc">
            <div class="page-header-desc-text">
              “新金融书系”是由上海新金融研究院（SFI）创设的书系，立足于创新的理念、前瞻的视角，追踪新金融发展足迹，探索金融发展新趋势， 
                <br> 求解金融发展新问题，力图打造高端、权威、新锐的书系品牌，传递思想，启迪新知。
            </div>
<!--            <div class="page-header-desc-link" v-if="tabId == 12">-->
<!--              <a href="javascript:void(0)">《新金融评论》订购须知</a>-->
<!--            </div>-->
          </div>
        </template>
        <!-- 时间轴 START -->
        <div class="page-swiper" v-if="tabList && tabList.length > 1 && tabId == 12">
          <swiper :active-index="activeIndex" :options="lineSwiperOption" class="page-swiper-content" ref="timeLineSwiper">
            <swiper-slide :class="{ 'slide-active': activeIndex == index }" v-for="(item, index) in timeList" :key="index">
              <div class="text-wrap">
                <span class="year" @click="slideTap(index)">{{ item.dict_label }}</span>
                <div class="line" v-if="timeList.length != index+1"></div>
              </div>
            </swiper-slide>
          </swiper>
          <div class="line-button-prev" @click="linePrev"></div>
          <div class="line-button-next" @click="lineNext"></div>
        </div>
        <!-- 时间轴 END -->
        <div class="page-book-wrap">
          <a :href="tabId == 12 ? '/news_detail/'+item.class_id+'/'+item.id :'/book_detail/'+item.class_id+'/'+item.id" target="_blank" class="page-book-wrap-item" v-for="(item, index) in dataList" :key="index">
            <div class="page-book-wrap-item-box">
              <div class="icon" v-if="item.litpic && item.litpic.img">
                <i class="point-left"></i>
                <i class="point-right"></i>
                <el-image :src="item.litpic.img"></el-image>
              </div>
              <div class="title">
                <span>{{ item.title }}</span>
              </div>
            </div>
          </a>
        </div>
        <el-empty :description="loading ? '正在加载中......' : '暂无内容'" v-if="!dataList || dataList.length == 0"></el-empty>
        <div class="page-list-pagination" v-if="page.total > page.pageSize">
          <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page="page.current"
              :page-size="page.pageSize"
              layout="prev, pager, next, jumper"
              :total="page.total">
          </el-pagination>
        </div>
        <div class="page-list-pagination-btn" v-if="page.total > page.pageSize">
          <el-button type="primary" size="medium" round @click="dataMore" v-if="!noneMore">查看更多</el-button>
          <div class="none-more" v-else>没有更多了</div>
        </div>
      </div>
    </div>
    <web-navigation></web-navigation>
    <web-footer></web-footer>
    <web-right-menu class="right-menu"></web-right-menu>
  </div>
</template>

<script>
import WebHeader from "@/components/webHeader";
import WebFooter from "@/components/webFooter";
import WebRightMenu from "@/components/webRightMenu";
import WebNavigation from "@/components/webNavigation";
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

import { articleSection, articleList, dictionaryList } from "@/api";
export default {
  name: 'Books',
  components: {
    WebHeader,
    WebFooter,
    WebRightMenu,
    WebNavigation,
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      loading: false,
      tabId: "",
      tabList: [],
      // 数据列表
      dataList: [],
      // 分页
      page: {
        // 当前页
        current: 1,
        // 每页显示的条数
        pageSize: 8,
        // 总条数
        total: 0
      },
      noneMore: false,

      activeIndex: 0,
      oldIndex: 0,
      // 时间轴数组
      timeList: [],
      // 选中的时间
      selectTime: "",
      // 时间轴轮播配置
      lineSwiperOption: {
        slidesPerView: 5,
        spaceBetween: 30,
        breakpoints: {
          320: {  //当屏幕宽度大于等于320
            slidesPerView: 2,
            spaceBetween: 10
          },
          768: {  //当屏幕宽度大于等于768
            slidesPerView: 3,
            spaceBetween: 20
          },
          1280: {  //当屏幕宽度大于等于1280
            slidesPerView: 5,
            spaceBetween: 30
          }
        }
      }
    }
  },
  created() {
    this.getTabList();
    this.getPublicationYears();
  },
  mounted() {
    // this.$refs.timeLineSwiper.initSwiper();
  },
  methods: {
    // 获取分类列表
    async getTabList() {
      let param = {
        orderBy: "sort",
        sortedBy: "desc",
        search: "parent_id:7",
        searchFields: "parent_id:="
      };
      let res = await articleSection(param);
      if (res && res.code == 200) {
        this.tabList = res.data.list;
        if (this.tabList && this.tabList.length > 0) {
          this.tabId = this.tabList[0].id;
          this.getTableData();
        }
      }
    },
    // 通过分类获取列表数据
    async getTableData() {
      this.loading = true;
      let param = {
        pageSize: this.page.pageSize,
        currentPage: this.page.current,
        orderBy: "sort",
        sortedBy: "desc",
        search: "",
        searchFields: ""
      };
      if (this.tabId == 12) {
        param.search = `year:${this.selectTime}`;
        param.searchFields = "year:=";
      }
      let res = await articleList(param, this.tabId);
      if (res && res.code == 200) {
        this.dataList = res.data.list;
        this.page.total = res.data.total;
      }
      this.loading = false;
    },
    // 获取出版物年份
    async getPublicationYears() {
      let param = {
        orderBy: "dict_sort",
        sortedBy: "desc",
        search: "dict_type:publication_year|dict_sort:0",
        searchFields: "dict_type:=|dict_sort:<>"
      };
      let res = await dictionaryList(param);
      if (res && res.code == 200) {
        this.timeList = res.data.list;
        if (res.data && res.data.list && res.data.list.length > 0) {
          this.selectTime = res.data.list[0]['dict_value'];
        }
      }
    },
    // 分类切换
    changeTab(row) {
      this.tabId = row.id;
      this.getTableData();
    },
    // 分页，选中的分页
    handleCurrentChange(val) {
      this.page.current = val;
      this.getTableData();
    },
    // 加载更多
    dataMore() {
      let ps = this.page.current + 1;
      if (this.page.total > ps*this.page.pageSize) {
        this.page.current = this.page.current + 1;
        this.getTableData();
      } else {
        this.noneMore = true;
      }
    },
    // 时间切换点击事件
    slideTap(index) {
      if (index > this.oldIndex) {
        this.lineNext(index);
      } else {
        this.linePrev(index);
      }
    },
    // 时间滚动上一页
    linePrev(index) {
      if (typeof index === 'number') {
        this.oldIndex = index + 1;
        this.activeIndex = index;
        this.selectTime = this.timeList[this.activeIndex].dict_value;
        this.$refs.timeLineSwiper.$swiper.slidePrev();
        this.getSwiperData();
      } else if (this.activeIndex > 0) {
        this.oldIndex = this.activeIndex
        this.activeIndex = this.activeIndex - 1;
        this.selectTime = this.timeList[this.activeIndex].dict_value;
        this.$refs.timeLineSwiper.$swiper.slidePrev();
        this.getSwiperData();
      }
    },
    // 时间滚动下一页
    lineNext(index) {
      if (typeof index === 'number') {
        this.oldIndex = index - 1;
        this.activeIndex = index;
        this.selectTime = this.timeList[this.activeIndex].dict_value;
        this.$refs.timeLineSwiper.$swiper.slideNext();
        this.getSwiperData();
      } else if (this.activeIndex < this.timeList.length - 1) {
        this.oldIndex = this.activeIndex
        this.activeIndex = this.activeIndex + 1;
        this.selectTime = this.timeList[this.activeIndex].dict_value;
        this.$refs.timeLineSwiper.$swiper.slideNext();
        this.getSwiperData();
      }
    },
    // 获取swiper的数据
    getSwiperData() {
      this.getTableData();
    }
  }
}
</script>

<style lang="scss">
.page-article-list {
  .page-header-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    &-item {
      width: 25%;
      height: 60px;
      display: inline-block;
      vertical-align: top;
      text-align: center;
      line-height: 60px;
      font-size: 20px;
      cursor: pointer;
      background: #f7f7f7;
      &.active {
        background: #004898;
        color: #ffffff;
      }
    }
  }
  .page-header-desc {
    margin-top: 60px;
    &-text {
      text-align: center;
      font-size: 14px;
      color: #333333;
    }
    &-link {
      display: flex;
      justify-content: flex-end;
      a {
        color: #2C83FF;
        display: block;
        margin-top: 10px;
      }
    }
  }
  .page-swiper {
    position: relative;
    margin: 40px 0;
    &-content {
      width: 80%;
      margin: 0 auto;
      .text-wrap {
        position: relative;
        font-size: 28px;
        line-height: 36px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .year {
          cursor: pointer;
        }
        .line {
          width: 100px;
          height: 10px;
          background: url('~@/assets/time_line.png') no-repeat;
          background-size: 100% 100% !important;
          position: absolute;
          left: 85px;
          display: inline-block;
        }
      }
      .slide-active {
        .text-wrap {
          color: #004898 !important;
        }
      }
    }
    .line-button-prev, .line-button-next {
      position: absolute;
      cursor: pointer;
      top: 0;
      color: #333;
      width: 35px;
      height: 35px;
    }
    .line-button-prev {
      left: 0;
      background: url('~@/assets/more_one.png') no-repeat;
      background-size: 100% 100%;
      &:hover {
        background: url('~@/assets/more_one_bg.png') no-repeat;
        background-size: 100% 100%;
      }
    }
    .line-button-next {
      right: 0;
      background: url('~@/assets/more_two.png') no-repeat;
      background-size: 100% 100%;
      &:hover {
        background: url('~@/assets/more_two_bg.png') no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .page-book-wrap {
    margin-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    &-item {
      width: calc(25% - 50px);
      margin-right: 66px;
      margin-bottom: 60px;
      &:nth-child(4n) {
        margin-right: 0!important;
      }
      &-box {
        border: 2px solid #909495;
        .icon {
          position: relative;
          height: 320px;
          .point-left, .point-right {
            display: inline-block;
            width: 8px;
            height: 8px;
            background-color: #909495;
            position: absolute;
          }
          .point-left {
            bottom: 0;
            left: 0;
          }
          .point-right {
            bottom: 0;
            right: 0;
          }
          .el-image {
            width: 100%;
            height: 320px;
          }
        }
        .title {
          border-top: 3px solid #909495;
          height: 75px;
          text-align: center;
          padding: 0 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            font-size: 16px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      &:hover {
        .page-book-wrap-item-box {
          border-color: #004898;
          .icon {
            .point-left, .point-right {
              background-color: #004898;
            }
          }
          .title {
            border-top-color: #004898;
          }
        }
      }
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .page-article-list {
    .page-swiper {
      margin: 20px 0;
      &-content {
        .text-wrap {
          font-size: 26px;
          line-height: 36px;
          .line {
            width: calc(100% - 75px);
            max-width: 129px;
            height: 12px;
            left: 75px;
          }
        }
      }
    }
    .page-header-tab {
      &-item {
        font-size: 16px;
        height: auto;
        line-height: 40px;
        &.active {
          background: #004898;
          color: #ffffff;
        }
      }
    }
    .page-book-wrap {
      &-item {
        width: calc(50% - 10px);
        margin-right: 20px;
        margin-bottom: 20px;
        &:nth-child(2n) {
          margin-right: 0 !important;
        }
        &-box {
          .icon {
            height: auto;
            .el-image {
              height: auto;
            }
          }
          .title {
            height: 100px;
            span {
              font-size: 22px;
            }
          }
        }
      }
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .page-article-list {
    .page-swiper {
      margin: 20px 0;
      &-content {
        width: 70%;
        .text-wrap {
          font-size: 22px;
          line-height: 36px;
          .line {
            width: calc(100% - 75px);
            max-width: 129px;
            height: 10px;
            left: 65px;
          }
        }
      }
    }
    .page-header-tab {
      &-item {
        font-size: 14px;
        height: auto;
        line-height: 40px;
        &.active {
          background: #004898;
          color: #ffffff;
        }
      }
    }
    .page-header-desc {
      margin-top: 60px;
      &-text {
        text-align: center;
        font-size: 14px;
        color: #333333;
      }
      &-link {
        display: flex;
        justify-content: flex-end;
        a {
          color: #2C83FF;
          display: block;
          margin-top: 10px;
        }
      }
    }
    .page-book-wrap {
      &-item {
        width: calc(50% - 10px);
        margin-right: 20px;
        margin-bottom: 20px;
        &:nth-child(2n) {
          margin-right: 0 !important;
        }
        &-box {
          .icon {
            height: auto;
            .el-image {
              height: auto;
            }
          }
          .title {
            padding: 0 10px;
          }
        }
      }
    }
  }
}
</style>