<template>
  <div class="web-navigation">
    <div class="f-wrap">
      <div class="f-wrap-content">
        <div class="f-wrap-content-item">
          <router-link :to="{ path: '/achievements' }" target="_blank">
            <img src="../../assets/navigation_one.png" alt="">
            <span>研究成果</span>
          </router-link>
        </div>
        <div class="f-wrap-content-item">
          <router-link :to="{ path: '/exchanges' }" target="_blank">
            <img src="../../assets/navigation_two.png" alt="">
            <span>学术交流</span>
          </router-link>
        </div>
        <div class="f-wrap-content-item">
          <router-link :to="{ path: '/about', hash: '#page3' }" target="_blank">
            <img src="../../assets/navigation_three.png" alt="">
            <span>组织架构</span>
          </router-link>
        </div>
        <div class="f-wrap-content-item">
          <router-link :to="{ path: '/trends' }" target="_blank">
            <img src="../../assets/navigation_four.png" alt="">
            <span>SFI动态</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WebNavigation',
  data() {
    return {}
  }
}
</script>
<style lang="scss" scoped>
.web-navigation {
  background: url("~@/assets/web_navigation_bg.jpg") no-repeat top center;
  background-size: cover;
  .f-wrap {
    &-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-item {
        position: relative;
        width: 25%;
        height: 249px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &:after {
          position: absolute;
          right: 0;
          top: 80px;
          content: "";
          width: 1px;
          height: 90px;
          border-left: 1px solid #e4e1da;
        }
        &:last-child {
          &:after {
            display: none;
          }
        }
        a {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          span {
            margin-top: 15px;
            display: block;
            font-size: 20px;
          }
        }
        &:hover {
          a {
            img {
              opacity: 0.7;
            }
          }
        }
      }
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .f-wrap {
    &-content {
      flex-wrap: wrap;
      &-item {
        width: 50% !important;
        padding: 20px 0;
        height: auto !important;
        &:after {
          top: 30px !important;
        }
        &:nth-child(2) {
          &:after {
            display: none;
          }
        }
      }
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .f-wrap {
    &-content {
      flex-wrap: wrap;
      &-item {
        width: 50% !important;
        padding: 20px 0;
        height: auto !important;
        &:after {
          top: 40px !important;
          height: 70px !important;
        }
        &:nth-child(2) {
          &:after {
            display: none;
          }
        }
      }
    }
  }
}
</style>